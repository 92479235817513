a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
    padding: 0;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul,
ol {
    list-style: none;
    margin: 0;
}

address {
    font-style: normal;
}

img {
    display: block;
    max-width: 100%;
    user-select: none;

    &[alt] {
        font-size: 0.7em;
        font-family: var(--ff-primary);
    }
}

button {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background-color: transparent;
}

svg {
    overflow: visible;
    width: 100%;
}
