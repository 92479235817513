@import "swiper/css/navigation";

#root,
html,
body {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

iframe {
    border: none;
}

* {
    @include scrollAppearance;
}

#xButtonEllipseFillId {
    transition: fill 0.5s ease;
    fill: var(--theme-feature-color);
}

.xButtonEllipseFillClass {
    transition: fill 0.5s ease;
    fill: var(--theme-feature-color);
    stroke: var(--theme-feature-color);
}

body {
    color: var(--clr-text-primary);
    font-family: var(--ff-primary);
    font-size: rem(16);
    touch-action: pan-y;
    background: var(--theme-custom-clr-background, var(--theme-clr-background-primary));
    background-attachment: fixed;
    min-width: rem(330);
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.native-ios {
        height: 100vh;
        position: relative;

        @media (orientation: landscape) {
            margin-right: env(safe-area-inset-right);
            margin-left: env(safe-area-inset-left);
        }

        &:after {
            content: "";
            display: block;

            width: 100%;
            height: env(safe-area-inset-top);

            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;

            background-color: var(--theme-clr-background-primary);
        }
    }
}

.no-scroll {
    overflow: hidden;
}

.swiper-button-prev,
.swiper-button-next {
    &.swiper-button-disabled {
        pointer-events: all !important;
    }
}

.native-ios {
    &.mobile-hidden {
        @media (orientation: landscape) and (max-width: map_get($MEDIA, lg)) {
            .main-content-wrapper {
                margin-top: 0;
            }
        }
    }
}
