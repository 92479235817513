:root {
    --clr-primary: var(--theme-clr-primary);

    --clr-accent: var(--theme-clr-accent);
    --clr-assist-primary: var(--theme-clr-assist-primary);
    --clr-assist-secondary: var(--theme-clr-assist-secondary);

    --clr-secondary: color-mix(in oklab, #{var(--clr-assist-secondary)} 80%, #{var(--clr-assist-primary)});
    --clr-sidepanel: color-mix(in oklab, #{var(--clr-assist-secondary)} 74%, #{var(--clr-assist-primary)});

    --clr-text-primary: var(--theme-clr-text-primary);
    --clr-text-primary50: #{color(from var(--clr-text-primary) srgb r g b / 50%)};
    --clr-text-primary01: #{color(from var(--clr-text-primary) srgb r g b / 60%)};
    --clr-text-primary02: #{color(from var(--clr-text-primary) srgb r g b / 90%)};

    --clr-text-secondary: var(--theme-clr-text-secondary);

    --clr-border-primary: var(--theme-custom-clr-border, #707070);
    --clr-border75: #{color(from var(--clr-border-primary) srgb r g b / 75%)};

    --clr-gray01: color-mix(in oklab, #{var(--clr-assist-secondary)} 60%, #{var(--clr-assist-primary)});
    --clr-gray02: color-mix(in oklab, #{var(--clr-assist-secondary)} 35%, #{var(--clr-assist-primary)});
    --clr-gray03: color-mix(in oklab, #{var(--clr-assist-secondary)} 69%, #{var(--clr-assist-primary)});
    --clr-gray04: color-mix(in oklab, #{var(--clr-assist-secondary)} 70%, #{var(--clr-assist-primary)});
    --clr-gray05: color-mix(in oklab, #{var(--clr-assist-secondary)} 58%, #{var(--clr-assist-primary)});

    --clr-background-modal: #{color(from var(--clr-light) srgb r g b / 50%)};
    --clr-background-primary: #{var(--theme-clr-background-primary)};

    --clr-light: #fff;
    --clr-light50: #{color(from var(--clr-light) srgb r g b / 50%)};
    --clr-light80: #{color(from var(--clr-light) srgb r g b / 80%)};
    --clr-light90: #{color(from var(--clr-light) srgb r g b / 10%)};
    --clr-dark: #000;
    --clr-dark50: #{color(from var(--clr-dark) srgb r g b / 50%)};
    --clr-dark80: #{color(from var(--clr-dark) srgb r g b / 80%)};

    --clr-success: var(--theme-clr-success);
    --clr-danger: var(--theme-clr-danger);
    --clr-blue: var(--theme-clr-blue);
    --clr-purple: var(--theme-clr-purple);

    --swiper-theme-color: var(--theme-clr-primary) !important;
}
